import { graphql } from 'gatsby';
import * as React from 'react';
// useI18next,
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { ListItem, ListItemText, Typography, Button } from '@material-ui/core';

import { LandingPageLayout } from '../components/layout';
import Seo from '../components/seo';

import { Section } from '../components/layout/Section';
import { UmoPromote } from '../components/UmoPromote';
import { BoxCompare } from '../components/BoxCompare';
import { EmbeddedAppPage } from '../components/EmbeddedAppPage';

const PageSafetyInstruction = ({ pageContext, data }) => {
  const { bannerImageCall } = data;
  const { t } = useTranslation(['safety-instruction', 'common']);
  const { language } = useI18next();

  const bannerImage = getImage(bannerImageCall);

  const [isInstructionOpen, setIsInstructionOpen] = React.useState(false);

  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />
      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.wasIstEineSicherheitsunterweisung')}>
          <Typography>{t('content.wasIstEineSicherheitsunterweisung')}</Typography>
          <Typography>{t('content.wasIstEineSicherheitsunterweisung2')}</Typography>
          <Section headingVariant="h3" heading={t('heading.unterschied')}>
            <Typography>{t('content.unterschied')}</Typography>
            <Typography>{t('content.unterschied2')}</Typography>
            <Typography>{t('content.unterschied3')}</Typography>
          </Section>
        </Section>

        <Section heading={t('heading.verantwortungsBereich')}>
          <Typography>{t('content.verantwortungsBereich')}</Typography>
          <Typography>{t('content.verantwortungsBereich2')}</Typography>
          <Typography>{t('content.verantwortungsBereich3')}</Typography>
          <Section headingVariant="h3" heading={t('heading.beauftragte')}>
            <Typography>{t('content.beauftragte')}</Typography>
            <Typography>{t('content.beauftragte2')}</Typography>
          </Section>
        </Section>

        <Section heading={t('heading.massnahmen')}>
          <Typography>{t('content.massnahmen')}</Typography>

          <ol>
            {[
              t('content.massnahmen2'),
              t('content.massnahmen3'),
              t('content.massnahmen4'),
              t('content.massnahmen5'),
              t('content.massnahmen6'),
            ].map((text) => {
              return (
                <ListItem style={{ display: 'list-item' }} key={text}>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              );
            })}
          </ol>
          <Typography>{t('content.massnahmen7')}</Typography>
          <Section headingVariant="h3" heading={t('heading.gefahrenIdentifizieren')}>
            <Typography>{t('content.gefahrenIdentifizieren')}</Typography>
            <ol>
              {[
                t('content.gefahrenIdentifizieren2'),
                t('content.gefahrenIdentifizieren3'),
                t('content.gefahrenIdentifizieren4'),
                t('content.gefahrenIdentifizieren5'),
                t('content.gefahrenIdentifizieren6'),
              ].map((text) => {
                return (
                  <ListItem style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ol>
            <Typography>{t('content.gefahrenIdentifizieren7')}</Typography>
          </Section>
        </Section>
        <Section heading={t('heading.methoden')}>
          <Typography>{t('content.methoden')}</Typography>
          <Section headingVariant="h3" heading={t('heading.vortrag')}>
            <Typography>{t('content.vortrag')}</Typography>
            <BoxCompare
              pros={[t('content.vortragVorteil'), t('content.vortragVorteil2'), t('content.vortragVorteil3')]}
              cons={[t('content.vortragNachteil'), t('content.vortragNachteil2')]}
            />
          </Section>
          <Section headingVariant="h3" heading={t('heading.workshops')}>
            <Typography>{t('content.workshops')}</Typography>
            <BoxCompare
              pros={[t('content.workshopsVorteil'), t('content.workshopsVorteil2'), t('content.workshopsVorteil3')]}
              cons={[t('content.workshopsNachteil'), t('content.workshopsNachteil2'), t('content.workshopsNachteil3')]}
            />
          </Section>
          <Section headingVariant="h3" heading={t('heading.elearning')}>
            <Typography>{t('content.elearning')}</Typography>
            <BoxCompare
              pros={[
                t('content.elearningVorteil'),
                t('content.elearningVorteil2'),
                t('content.elearningVorteil3'),
                t('content.elearningVorteil4'),
                t('content.elearningVorteil5'),
              ]}
              cons={[t('content.elearningNachteil'), t('content.elearningNachteil2')]}
            />
            <UmoPromote />
          </Section>
        </Section>
        <Section heading={t('heading.example')}>
          <Typography>{t('content.example')}</Typography>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setIsInstructionOpen(true);
            }}
          >
            {t('button.example')}
          </Button>
          <EmbeddedAppPage
            open={isInstructionOpen}
            onClose={() => {
              setIsInstructionOpen(false);
            }}
            mode="full-screen"
            id="62eb85c97c1b800012395668"
            token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbmlzYXRpb25JZCI6IjYyMjY1YWVkMjdiYWM1MDAxMzQ4NDhmNCIsInR5cGUiOiJvcGVuLWluc3RydWN0aW9uIiwiaWF0IjoxNjg1NzA2NTMwLCJleHAiOjE4NDM0OTQ1MzB9.1EPZbK1Uen-9Zl6WJ6l69vpFZsCMN6YbJngCwk9fZ1I"
            title="Eingebettete Bürounterweisung von UMO"
            lng={language}
            queryParams={{
              firstName: t('guest'),
              lastName: t('guest'),
              identification: t('identification'),
            }}
          />
        </Section>
      </LandingPageLayout>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "safety-instruction.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
  }
`;

export default PageSafetyInstruction;
